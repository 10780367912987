import React from "react";
import SimpleBar from "simplebar-react";

const LeftColumn = ({ headerHeight, leftColumnHeight, leftColumnRef }) => {
  return (
    <div
      id="left-column"
      ref={leftColumnRef}
      className="bg-light p-2"
      style={{
        position: "fixed",
        top: headerHeight,
      }}
    >
      <SimpleBar
        style={{
          height: leftColumnHeight,
        }}
      >
        <h3>メニュー</h3>
        <ul id="list" className="pl-4">
        <li>==========</li>
          <li>
					  <a href="#prepare" class="link link--io">初日の準備</a>
				  </li>
          <li>==========</li>
          <li>
					  <a href="#agenda" class="link link--io">アジェンダ</a>
				  </li>
          <li>==========</li>
          <li>
					  <a href="#modules" class="link link--io">参考リンク</a>
				  </li>
          <li>==========</li>
          <li>
					  <a href="#groupwork" class="link link--io">グループワーク</a>
				  </li>
          <li>==========</li>
          <li>
					  <a href="#survey" class="link link--io">アンケート</a>
				  </li>
          <li>==========</li>
          <li>
					  <a href="#faq" class="link link--io">FAQ</a>
				  </li>
          <li>==========</li>
        </ul>
      </SimpleBar>
    </div>
  );
};

export default LeftColumn;
