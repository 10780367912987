import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>Architecting on AWS へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/architecting-on-aws/" target="blank">Architecting on AWS について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>Webex</h5>
        <p>トレーニング期間中は配信環境としてWebexを利用します。<br/>配信の他、「挙手」「グループワークでの会話」にも利用します。<br/>ログインする際には実名で（漢字・ローマ字問いません）ご登録をお願いします。<br/><a href="https://awsj-tc.s3-ap-northeast-1.amazonaws.com/Operations/Manuals/Public_Private/Tool/AWS%E3%82%AA%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%88%E3%83%AC%E3%83%BC%E3%83%8B%E3%83%B3%E3%82%B0_Webex%E3%81%AE%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E6%96%B9%E6%B3%95.pdf" target="blank">Webex の使い方 (PDF)</a></p>
        <hr/>
        <h5>Rocket Chat</h5>
        <p>
          トレーニング期間中は<br/>
          =====<br/>
          ・インストラクター～参加者<br/>
          ・参加者～参加者<br/>
          =====<br/>
          のテキストコミュニケーションに<b>"Rocket Chat"</b>というツールを利用します。<br/>
          基本的にWebexのチャットではなく、Rocket Chat で質問したり会話をするように統一しましょう。
          <div class="blackboard-box">
            <p><b><span class="warn">Rocket Chat とは？</span></b></p>
            <p>Slack に似たツールで絵文字スタンプやスレッド投稿が可能です</p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
          （Rocket Chat の URL は講義内にて適宜ご連絡致します）
        </p>
        <hr/>
        <h5>テキストのダウンロード</h5>
        <p>
          トレーニングで利用するテキストは、Gilmore Bookshelf という電子テキスト配布システムで参照します。<br/>
          下記URLから利用手順をダウンロードして、テキストの引き換えを行ってください。<br/>
          <div class="blackboard-box">
            <p>
              <b>
                <span class="warn">
                  事前案内でテキストをダウンロード済みの方は、こちらの手順は不要です。<br/>
                  （基本的にはクラスルーム初日の朝、Webex にてライセンスコードをお配りしております。）
                </span>
              </b>
            </p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
          <a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">テキストの利用手順 (PDF)</a>
        </p>
        <hr/>
        <h5>ハンズオン環境へのログイン</h5>
        <p>
          トレーニングでは実際に AWS のアカウントを使用してハンズオンが行える QwikLabs というシステムを利用します。<br/>
          下記 URL の利用手順を参照して、ハンズオンが実施可能な状態にしておいてください。<br/>
          <a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/QwikLabsGuide.pdf" target="blank">ハンズオン利用手順 (PDF)</a><br/>
          <a href="https://aws.qwiklab.com/" target="blank">QwikLabs へログイン</a>
          <div class="blackboard-box">
            <p>
              <b>
                <span class="warn">
                  Qwiklabs へのログイン後に対象のクラスルームのパネルが表示されていない場合、異なるメールアドレスで登録されている可能性があります。<br/>
                  パネルが表示されていない場合は Qwiklabs にご登録頂いたメールアドレスで登録し直しますので講師側までメールアドレスをご教示下さい。
                </span>
              </b>
            </p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
        </p>
        <hr/>
        <h5>Google Slides へアクセス</h5>
        <p>
          本トレーニングではグループワークを用意しています。その際にGoogle Slides を利用します。<br/>
          下記URLへアクセスできるかご確認ください。 (確認用の為、下記 URL は本利用しません。)<br/>
          <a href="https://docs.google.com/presentation/d/1teOZTSorFLgsIENdOp_rhYPYI6_PAMkfvLZeWr7883Y/edit?usp=sharing" target="blank">グループワーク資料 (Google Slides)</a>
          <div class="blackboard-box">
            <p><b><span class="warn">セキュリティによりアクセスできない場合は Rocket Chat へその旨投稿してください</span></b></p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          本トレーニング内で適宜まとめております資料につきましては別途 PDF にて切り出しております。 (転送等はお控え下さいますよう、お願い致します。)<br/>
          <a href="https://arch-appendix.s3.ap-northeast-1.amazonaws.com/ArchitectingOnAWS_Module_Appendix_6.8.pdf" target="blank">トレーニング付加資料 (PDF)</a>
        </p>
        
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>はじめに</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>最もシンプルなアーキテクチャ</td>
            <td><span class="point_box"><b>ラボ1</b></span>静的ウェブサイトのホスティング</td>
          </tr>
          <tr>
            <td>3</td>
            <td>コンピューティングレイヤーを追加する</td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>データベースレイヤーを追加する</td>
            <td><span class="point_box"><b>ラボ2</b></span>AWS でウェブアプリケーションをデプロイする</td>
          </tr>
          <tr>
            <td>5</td>
            <td>AWS におけるネットワーク (パート 1)</td>
            <td></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 2</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>6</td>
            <td>AWS におけるネットワーク (パート 2)</td>
            <td><span class="point_box"><b>ラボ3</b></span>仮想プライベートクラウドを作成する</td>
          </tr>
          <tr>
            <td>7</td>
            <td>AWS Identity and Access Management (IAM)</td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>伸縮性、高可用性、モニタリング</td>
            <td><span class="point_box"><b>ラボ4</b></span>高可用性の環境を作成する</td>
          </tr>
          <tr>
            <td>グループワーク</td>
            <td>既存のアプリケーションのフォークリフト</td>
            <td><a href="#groupwork">進行手順の補足</a></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 3</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>9</td>
            <td>オートメーション</td>
            <td><span class="point_box"><b>ラボ5</b></span>AWS CloudFormation を使用した<br/>インフラストラクチャのデプロイのオートメーション</td>
          </tr>
          <tr>
            <td>10</td>
            <td>キャッシュ</td>
            <td></td>
          </tr>
          <tr>
            <td>11</td>
            <td>疎結合アーキテクチャの構築</td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>マイクロサービスとサーバーレスアーキテクチャ</td>
            <td><span class="point_box"><b>ラボ6</b></span>サーバーレスアーキテクチャを実装する</td>
          </tr>
          <tr>
            <td>13</td>
            <td>RTO/RPO およびバックアップの復旧設定 </td>
            <td></td>
          </tr>
          <tr>
            <td>エンディング</td>
            <td>コースのまとめ＆アンケート入力</td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール1</h5>
        ・<a href="http://api.open-notify.org/iss-now.json" target="blank">＜デモ＞iss-now.json (国際宇宙ステーションの緯度経度を知る事が出来る API)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- API を体験する身近なデモ<br/>
        ・<a href="https://aws.amazon.com/jp/aws-ten-reasons/" target="blank">AWS の クラウドが選ばれる 10 の理由</a><br/>
        ・<a href="https://aws.amazon.com/jp/architecture/well-architected/" target="blank">AWS Well-Architected</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Well-Architected の概要ページ<br/>
        ・<a href="https://wa.aws.amazon.com/wellarchitected/2020-07-02T19-33-23/index.ja.html" target="blank">AWS Well-Architected フレームワーク (Well-Architected 側)</a><br/>
        ・<a href="https://d1.awsstatic.com/International/ja_JP/Whitepapers/AWS_Well-Architected_Framework_2018_JA_final.pdf" target="blank">AWS Well-Architected フレームワーク (PDF)</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/latest/framework/welcome.html" target="blank">AWS Well-Architected フレームワーク (Docs)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/techblog-well-architected-reliability-1/" target="blank">Well-Architected for Startups -信頼性の柱- 導入編</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 「信頼性の柱」をベースにモジュール 1 の内容をコンパクトにまとめた記事<br/>
        ・<a href="https://aws.amazon.com/jp/well-architected-tool/" target="blank">AWS Well-Architected Tool</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS の 1 サービスであるアーキテクチャのレビューツールに関する概要ページ<br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/" target="blank">グローバルインフラストラクチャ</a><br/>
        ・<a href="https://aws.amazon.com/jp/local/osaka-region/" target="blank"> AWS 大阪リージョン</a><br/>
        ・<a href="https://www.cloudping.info/" target="blank">＜デモ＞CloudPing.info</a>
        <hr/>
        <h5>モジュール2</h5>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-41_AWS_Summit_Online_2021_STG02.pdf" target="blank">進化したAmazon S3 を最大限に活用する</a><br/>
        ・<a href="https://aws.amazon.com/jp/s3/faqs/" target="blank">Amazon S3 のよくある質問</a><br/>
        ・<a href="http://s3-accelerate-speedtest.s3-accelerate.amazonaws.com/en/accelerate-speed-comparsion.html" target="blank">＜デモ＞Amazon S3 Transfer Acceleration Speed Comparison</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- S3 Transfer Acceleration を利用してどれくらい速度が変わるのかを体感出来るデモ<br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2017/summit/slide/D2T6-4.pdf#page=17" target="blank">＜事例＞音楽配信プラットフォームとAWS Snowballを利用したデータ移行 (P. 17～)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 300TB のワンポイントの大量データを Snowball で移行した事例<br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/kddi_2022/" target="blank">＜事例＞KDDI株式会社 (Snowball Edge)</a><br/>
        ・<a href="https://www.youtube.com/watch?v=8vQmTZTq7nw" target="blank">AWS re:Invent 2016: Move Exabyte-Scale Data Sets with AWS Snowmobile</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- re:Invent 2016 紹介時の Snowmobile デモ (息抜きに是非ご覧下さい)<br/>
        ・<a href="https://aws.amazon.com/jp/s3/storage-classes/#Performance_across_the_S3_Storage_Classes" target="blank">Amazon S3 ストレージクラス（パフォーマンスチャート）</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/tv-tokyo/" target="blank">＜事例＞株式会社テレビ東京 (S3 Glacier)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- S3 Glacier をコストを抑えて頂く観点でご活用頂いた事例<br/>
        ・<a href="https://aws.amazon.com/jp/s3/pricing/" target="blank">Amazon S3 の料金</a>
        <hr/>
        <h5>モジュール3</h5>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/C2-07.pdf#page=38" target="blank">Amazon EC2 インスタンスタイプの選び方ガイド</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- インスタンスファミリーの基準は「M」を基準に考慮する<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSEC2/latest/UserGuide/ebs-volume-types.html" target="blank">Amazon EBS ボリュームの種類</a><br/>
        ・<a href="https://www.slideshare.net/AmazonWebServicesJapan/20190306-aws-black-belt-online-seminar-amazon-ec2/18" target="blank"> 20190306 AWS Black Belt Online Seminar Amazon EC2スポットインスタンス (スポット解説)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/how-dena-succesfully-applied-ec2-spot-on-production-and-reference-architecture-using-containers/" target="blank">株式会社ディー・エヌ・エー様におけるEC2スポットインスタンスの大規模活用のための工夫とコンテナ技術を用いた設計例の紹介</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- スポットインスタンス運用に関する様々な工夫が取り込まれた事例<br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200420_AWS_Savings_Plans_1pager.pdf" target="blank">Savings Plans でコスト最適化</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Savings Plans の種類と RI との比較について<br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-13_AWS_Summit_Online_2021_CMP01.pdf#page=19" target="blank">Amazon EC2ことはじめ 〜あらゆるワークロードに対応する豊富な選択肢とコスト最適化オプション〜 (コスト部分 P. 19～)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- コスト最適化に関する図示とワークロード別パターンがまとまっている資料<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSEC2/latest/UserGuide/placement-groups.html" target="blank">プレイスメントグループ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- EC2 のインスタンス起動に関してより発展的な形でご利用頂く為の活用例に関して
        <hr/>
        <h5>モジュール4</h5>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-10_AWS_Summit_Online_2021_DAT01.pdf#page=22" target="blank">今日からはじめる︕AWSのデータベースと最適なサービスの選び方 (P. 22)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 「Purpose-built databases」の考え方に沿った AWS のデータベースサービスの選び方<br/>
        ・<a href="https://aws.amazon.com/jp/getting-started/hands-on/create-nosql-table/" target="blank">NoSQL テーブルを作成してクエリを実行する</a><br/>
        ・<a href="https://www.youtube.com/watch?v=h1r8AzOdlqo" target="blank">AWS におけるデータベースの選択指針 | AWS Summit Tokyo 2019 (Youtube)</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/b-en-g/" target="blank">＜事例＞ビジネスエンジニアリング株式会社 (RDS for PostgreSQL)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- RDS for PostgreSQL のご利用によりインスタンスコストを抑制した事例<br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/nintendo-dena-2020/" target="blank">＜事例＞任天堂株式会社、株式会社ディー・エヌ・エー (Aurora)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Aurora の活用により「スケーラビリティ、パフォーマンス、運用工数」の低減に着眼した事例<br/>
        ・<a href="https://www.allthingsdistributed.com/2007/10/amazons_dynamo.html" target="blank">Amazon's Dynamo</a><br/>
        ・<a href="https://d0.awsstatic.com/events/jp/2017/summit/slide/D3T5-3.pdf" target="blank">＜事例＞サーバレスアーキテクチャで実現した『M-1グランプリ』敗者復活戦投票システム</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- サーバーレスアーキテクチャの構築の一環として DynamoDB をご活用頂いた事例<br/>
        ・<a href="https://speakerdeck.com/paypay/paypaydefalsedynamodbhuo-yong-shi-li-nituite" target="blank">＜事例＞PayPayでのDynamoDB活用事例について</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20170919_AWS-BlackBelt-DMS.pdf#page=19" target="blank">【AWS Black Belt Online Seminar】AWS Database Migration Service (P. 19)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS DMS を利用する上での利用チャート (意思決定のご参考に)<br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/first-step-of-dms/" target="blank">はじめてAWS DMSを検討する際に読んでいただきたいこと</a>
        <hr/>
        <h5>モジュール5</h5>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-32_AWS_Summit_Online_2021_NET01.pdf" target="blank">初心者向け Amazon VPC を中心としたネットワークの構成解説！</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- モジュール 5 に関連するサービス／コンポーネントを分かりやすくまとめている資料<br/>
        ・<a href="https://cidr.xyz/" target="blank">＜デモ＞CIDR.xyz</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- CIDR 設計時に IP アドレスと CIDR を入力するとグラフィカルに計算結果を表示してくれる Web アプリ
        <hr/>
        <h5>モジュール6</h5>
        ・<a href="https://aws.amazon.com/jp/directconnect/partners/" target="blank">AWS Direct Connect デリバリーパートナー</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200219_BlackBelt_Onpremises_Redundancy.pdf" target="blank">[AWS Black Belt Online Seminar] オンプレミスとAWS間の冗長化接続</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20191105_AWS_Blackbelt_Route53_Hosted_Zone_A.pdf#page=42" target="blank">[AWS Black Belt Online Seminar]  Amazon Route 53 Hosted Zone (P. 42～49)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Route 53 の各種トラフィックルーティングに関してまとまっている資料
        <hr/>
        <h5>モジュール7</h5>
        ・<a href="https://aws.amazon.com/jp/blogs/news/systematic-approach-for-least-privileges-jp/" target="blank">最小権限実現への4ステップアプローチ 前編</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/best-practices.html" target="blank">IAM でのセキュリティのベストプラクティス</a><br />
        &nbsp;&nbsp;&nbsp;&nbsp;- IAM を運用していく上でのベストプラクティス、着手すべき点。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/access_policies.html#access_policy-types" target="blank">ポリシータイプ</a><br />
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS にはどのようなポリシーがあるのか使用頻度の高い順から記載<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/id_roles_providers_enable-console-saml.html" target="blank">SAML 2.0 フェデレーティッドユーザーが AWS Management Console にアクセス可能にする</a><br />
        &nbsp;&nbsp;&nbsp;&nbsp;- 既存の認証資産 (Active Directory　等) を利用した AWS マネジメントコンソールへのアクセスに関して<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_manage_policies_scps_examples.html" target="blank">サービスコントロールポリシーの例</a><br />
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS におけるポリシーの一種である「サービスコントロールポリシー」の利用例<br/>
        <hr/>
        <h5>モジュール8</h5>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/smartnews/" target="blank">＜事例＞スマートニュース株式会社 (Auto Scaling)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- ニュースアプリで活用されている Auto Scaling の事例<br/>
        ・<a href="https://www.youtube.com/watch?v=0Ps7itUdBYw" target="blank">【初級】クラウド環境におけるモニタリングの重要性について | AWS Summit Tokyo 2019 (Youtube)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS クラウド環境におけるモニタリングの各種ポイント (CloudWatch, CloudTrail 等)<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/autoscaling/plans/userguide/how-it-works.html" target="blank">スケーリングプランの仕組み</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Auto Scaling のスケーリング戦略に関する公式ドキュメント<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/autoscaling/ec2/userguide/lifecycle-hooks.html" target="blank">Amazon EC2 Auto Scaling のライフサイクルフック</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Auto Scaling のスケーリング時に考慮すべき点<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonRDS/latest/UserGuide/USER_ReadRepl.html" target="blank">リードレプリカの使用</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- RDS 環境におけるリードレプリカの使用について<br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/rds-mysql-downtime-impact/" target="blank">マルチ AZ 配置は、Amazon RDS MySQL の変更中のダウンタイムを削減するのに役立ちますか?</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- DB インスタンスの設定変更に関するダウンタイムについてまとめたナレッジセンター記事<br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/Session%204%20-%2020200825_CyberZ_OPENRECTV_Purpose-Built-Databases-Week-v2.pdf#page=28" target="blank">＜事例＞OPENREC.tv における Amazon DynamoDB を用いた スケーラブルなアプリケーション設計 (P. 28)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- DynamoDB のオンデマンドモードを活用頂いた事例<br/>
        ・<a href="https://www.youtube.com/watch?v=16RYHfe89WY" target="blank">Amazon DynamoDB Deep Dive | AWS Summit Tokyo 2019 (Youtube 21:24～22:43)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 同じく DynamoDB のオンデマンドモードについて解説している AWS Summit 動画
        <hr/>
        <h5>モジュール9</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200826_AWS-BlackBelt_AWS-CloudFormation.pdf#page=82" target="blank">[AWS Black Belt Online Seminar] AWS CloudFormation (P. 82)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- CloudFormation のライフサイクル別テンプレート管理について<br/>
        ・<a href="https://aws.amazon.com/jp/quickstart/" target="blank">AWS クイックスタート</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS のベストプラクティスに沿った人気の高い技術を AWS にデプロイ (CloudFormation を学ぶ上でも有用)<br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/honda/" target="blank">＜事例＞本田技研工業株式会社 (CloudFormation)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- CloudFormation の利用で海外の拠点への展開期間を短縮した事例<br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200212_AWSBlackBelt_SystemsManager_0214.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Systems Manager</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Systems Manager を深掘りする為のサービスカット資料
        <hr/>
        <h5>モジュール10</h5>
        ・<a href="https://aws.amazon.com/jp/cloudfront/features/" target="blank">Amazon CloudFront の主な特徴</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- グローバルエッジネットワークに関する振り返り<br/>
        ・<a href="https://aws.amazon.com/jp/cloudfront/customers/" target="blank">Amazon CloudFront のお客様</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/prime-day-2021-two-chart-topping-days/" target="blank">プライムデー 2021 – トップチャートの 2 日間</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- CloudFront がプライムデーに 1 分 2 億 9,000 万件を超える HTTP リクエストのピーク負荷を処理した件 (Aurora / DynamoDB / SQS の記載もあり)<br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2017/summit/slide/D3T4-7.pdf#page=15" target="blank">Architecting for the Cloud クラウドにおけるアーキテクチャの設計原則 (P. 15)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- ステートレスにする為のセッション情報の扱いとは<br/>
        ・<a href="https://aws.amazon.com/jp/elasticache/redis-vs-memcached/" target="blank">Redis と Memcached の比較</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Redis / Memcached の使いどころのポイントがまとめられている公式資料
        <hr/>
        <h5>モジュール11</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20190717_AWS-BlackBelt_AmazonSQS.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Simple Queue Service</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- SQS の細かな仕様に関してもう少し Deep に入り込む為の Blackbelt 資料<br/>
        ・<a href="https://d0.awsstatic.com/events/jp/2017/summit/devday/D2T8-5.pdf" target="blank">Sansanがメッセージング (SQS) でスケーラビリティを手に入れた話</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- SQS のご活用例として課題解決およびご利用上の注意点がまとまった資料 (標準キュー)<br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202104/serverless-idempotency/" target="blank">サーバーレスが気になる開発者に捧ぐ「べき等性」ことはじめ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 「べき等性」のキーワードの理解を深める為の補助資料 (モジュール 12 の内容も含む)<br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20190604_AWS-Blackbelt_AmazonSNS.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Simple Notification Service (SNS)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- SNS の細かな仕様に関してもう少し Deep に入り込む為の Blackbelt 資料
        ・<a href="https://aws.amazon.com/jp/getting-started/hands-on/send-fanout-event-notifications/" target="blank">＜ハンズオン＞ファンアウトイベント通知を送信する</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- SNS の特徴でもある「ファンアウト」について簡易的に実施可能なハンズオン<br/>
        ・<a href="https://www.youtube.com/watch?v=8cAk1_GSV0g" target="blank">SQS/SNS FIFO メッセージングデモ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- メッセージングの流れを分かりやすく解説している動画
        <hr/>
        <h5>モジュール12</h5>
        ・<a href="https://aws.amazon.com/jp/microservices/" target="blank">マイクロサービス</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS におけるマイクロサービスの概要／サービス紹介<br/>
        ・<a href="https://www.youtube.com/watch?v=Zf-DW_u_B40" target="blank">〜マイクロサービスを設計する全ての開発者に送る〜クラウド時代のマイクロサービス設計徹底解説！｜AWS Summit Tokyo 2017 (Youtube)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/techblog-microservices-introduction/" target="blank">スタートアップのためのマイクロサービス入門</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/paypay/" target="blank">＜事例＞PayPay株式会社</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS のマイクロサービスアーキテクチャを活用し 3,300 万人 (2020 年 10 月時点) が利用する QR コード決済サービスを 3 ヶ月でリリース<br/>
        ・<a href="https://aws.amazon.com/jp/containers/" target="blank"> AWS でのコンテナ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS におけるコンテナの概要／サービス紹介<br/>
        ・<a href="https://aws.amazon.com/jp/getting-started/hands-on/break-monolith-app-microservices-ecs-docker-ec2/" target="blank">＜ハンズオン＞モノリシックアプリケーションをマイクロサービスに分割する</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- ECS / Docker / EC2 を利用したモノリシックアプリケーションのマイクロサービス分割ハンズオン<br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/04_%E3%82%A8%E3%82%A6%E3%83%AC%E3%82%AB%E6%A7%98_%E8%B3%87%E6%96%99.pdf" target="blank">＜事例＞Pairs,Pairsエンゲージにおける ECS Fargateの移行・活用事例</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Fargate の移行・活用事例<br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/202108_AWS_Black_Belt_Containers202-ECSFargate.pdf" target="blank">ECS での Fargate 入門</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- ECS × Fargate の Blackbelt 概要資料<br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/202107_AWS_Black_Belt_Container350-Container_and_Serverless.pdf" target="blank">コンテナとサーバレスの使い分け</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- コンテナ／サーバーレスのディシジョンツリーや構成例が記載されている資料
        <hr/>
        <h5>モジュール13</h5>
        ・<a href="https://aws.amazon.com/jp/blogs/news/disaster-recovery-dr-architecture-on-aws-part-iii-pilot-light-and-warm-standby/" target="blank">AWS のディザスタリカバリ (DR) アーキテクチャ、パート III: パイロットライトとウォームスタンバイ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- パイロットライトまたはウォームスタンバイの実装時の考慮点<br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/disaster-recovery-dr-architecture-on-aws-part-iv-multi-site-active-active/" target="blank">AWS のディザスタリカバリ (DR) アーキテクチャ、パート IV: マルチサイトアクティブ/アクティブ</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- マルチサイトアクティブ/アクティブの実装時の考慮点<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/fis/latest/userguide/what-is.html" target="blank">AWS Fault Injection Simulator とは</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202110/awsgeek-fault-injection-simulator/" target="blank">カオスエンジニアリングで本当にカオスにならないための進め方をグラレコで解説</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 有事の際に備えた事前の検証を AWS Fault Injection Simulator で実践する概要説明資料
        <hr/>
        <h5>その他（順不同）</h5>
        ----- 【トレーニング情報】<br/>
        ・<a href="https://aws.amazon.com/jp/training/classroom/" target="blank">AWS クラスルームトレーニングの概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202203/way-to-choose-training/" target="blank">AWS 認定インストラクターによる有償トレーニングコースの選び方</a><br/>
        ----- 【各種資料】<br/>
        ・<a href="https://aws.amazon.com/jp/aws-jp-introduction/aws-jp-webinar-level-100/" target="blank">AWS 初心者向け資料</a><br/>
        ・<a href="https://aws.amazon.com/jp/aws-jp-introduction/aws-jp-webinar-service-cut/" target="blank">AWS サービス別資料</a><br/>
        ・<a href="https://www.youtube.com/watch?v=FbnneJ17TA0" target="blank">Architecting for the Cloud 2019 -クラウドにおけるアーキテクチャの設計原則 | AWS Summit Tokyo 2019 (Youtube)</a><br/>
        ----- 【アーキテクチャ図】<br/>
        ・<a href="https://aws.amazon.com/jp/architecture/icons/" target="blank">AWS アーキテクチャアイコン</a><br/>
        ----- 【料金関連】<br/>
        ・<a href="https://calculator.aws/#/" target="blank">AWS 料金見積りツール</a>
        <hr/>
      </div>
      <hr class="koji"/>
      <h3 id="groupwork" class="anchor">グループワーク</h3>
      <div class="box">
        <h5>グループワーク資料</h5>
        <p>
          <a href="https://arch-appendix.s3.ap-northeast-1.amazonaws.com/ArchitectingOnAWS_GroupDiscussion.pdf" target="blank">グループワーク資料 (PDF)</a>
        </p>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>ハンズオン（Qwiklabs）</h5>
        Q. トレーニング期間中、ハンズオン環境はいつまで利用できますか？<br/>
        &nbsp;&nbsp;・トレーニング最終日の 23 時まで利用可能です。<br/>
        &nbsp;&nbsp;・ただし、3回 / Lab / 人 の制限がありますので、お気をつけください。<br/><br/>
        Q. ハンズオン環境のタイマーのような表示はなんですか？<br/>
        &nbsp;&nbsp;・ハンズオン環境は一度起動すると AWS アカウントが払い出され、タイマーによってカウントダウンが始まります。<br/>
        &nbsp;&nbsp;・タイマーが 00:00:00 になると起動したハンズオン環境へのサインインができなくなり、起動した AWS アカウント上のリソースがワイプ (削除)されます<br/><br/>
        Q. ハンズオン環境で<b><font color="red">ラボ終了</font></b>をクリックするとどうなりますか？<br/>
        &nbsp;&nbsp;・ハンズオン環境へのサインインができなくなり、起動したAWSアカウント上のリソースがワイプ (削除)されます。
        <hr/>
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから730日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a>
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日を目処に削除します。
      </div>
    </main>
  );
};

export default MainContents;
